import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { usePocket } from "../../PocketContext";
import { FcGoogle } from "react-icons/fc";

export const Auth = () => {
  const navigate = useNavigate();
  const { login, user } = usePocket();

  const onLogin = async () => {
    try {
      await login();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  return (
    <section>
      <div className={"w-fit flex flex-col gap-2"}>
        <h1 className={"card-title"}>Acceso</h1>
        <p>
          Accede a tu cuenta de <span className={"font-bold"}>OposQuiz</span>
        </p>

        <button className={"btn btn-outline btn-primary"} onClick={onLogin}>
          <FcGoogle size={30} />
          <span>Iniciar sesión con Google</span>
        </button>
      </div>
    </section>
  );
};
