import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePocket } from "../../../../PocketContext";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { usePocketActions } from "../../../../hooks/use-pocket-actions";
import { MdDeleteForever } from "react-icons/md";
import { Loader } from "../../../../components/loader";

export const Questions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = usePocket();
  const { getQuestions, deleteQuestion } = usePocketActions();

  const {
    data: questions,
    isLoading,
    mutate,
  } = useSWR(
    `/getQuestions/${params?.topicId}`,
    getQuestions(params?.topicId),
    { fallbackData: [] },
  );
  const { trigger: onDelete } = useSWRMutation(
    "/deleteQuestion",
    async (_, { arg }) => await deleteQuestion(arg),
    {
      onSuccess: async () => await mutate(),
    },
  );

  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (
      !user ||
      (user.email !== "alberto.quil3s@gmail.com" &&
        user.email !== "yeissuaf@gmail.com")
    ) {
      navigate("/");
      return;
    }
  }, [navigate, user]);

  const modalRef = useRef(null);

  const onShowModalDelete = (id) => () => {
    setDeleteId(id);
    modalRef.current.showModal();
  };

  const onHideModalDelete = () => {
    setDeleteId(null);
  };

  // TODO: add buscador (?)
  return (
    <section>
      <div className={"flex justify-between"}>
        <h1 className={"text-2xl"}>{`Preguntas (${questions.length})`}</h1>
        <button className={"btn btn-secondary"} onClick={() => navigate("add")}>
          Crear pregunta
        </button>
      </div>

      {isLoading && <Loader />}

      <div className="overflow-x-auto">
        <table className="table table-zebra">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>Pregunta</th>
              <th>Respuestas</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {questions.map(({ id, question, answers }, i) => (
              <tr key={`kQuestion-${id}`}>
                <th>{i + 1}</th>
                <td>{question}</td>
                <td>
                  <div className={"flex flex-col"}>
                    {answers.map((answer) => (
                      <span
                        key={`kAnswer-${answer.id}`}
                        className={
                          answer.isCorrect ? "text-green-400" : "text-red-400"
                        }
                      >
                        {answer.answer}
                      </span>
                    ))}
                  </div>
                </td>
                <td>
                  <button className={"btn"} onClick={onShowModalDelete(id)}>
                    <MdDeleteForever size={24} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <dialog
        ref={modalRef}
        id="deleteQuestion"
        className="modal"
        onClose={onHideModalDelete}
      >
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">¿Quieres borrar la pregunta?</h3>
          <div className={"flex mt-4 justify-end gap-4"}>
            <button
              className={"btn btn-md btn-wide btn-neutral"}
              onClick={() => modalRef.current.close()}
            >
              No
            </button>
            <button
              className={"btn btn-md btn-warning"}
              onClick={() => {
                onDelete(deleteId);
                modalRef.current.close();
              }}
            >
              Si
            </button>
          </div>
        </div>
      </dialog>
    </section>
  );
};
