import {
  LuBadgeCheck,
  LuBadgeAlert,
  LuBadgeHelp,
  LuTestTube2,
} from "react-icons/lu";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const Topic = ({ topic }) => {
  const navigate = useNavigate();

  // const progress = useMemo(() => {
  //   // TODO: tener en cuenta las falladas
  //   if (module.totalQuestions === 0) return 0;
  //   return (
  //     ((module.failedQuestions + module.correctQuestions) * 100) /
  //     module.totalQuestions
  //   );
  // }, [module]);

  const undiscoverQuestions = useMemo(
    () =>
      Math.max(
        0,
        topic.totalQuestions - topic.failedQuestions - topic.correctQuestions,
      ),
    [topic],
  );

  const onOpenQuiz = () => {
    navigate(`topic/${topic.topicId}/quiz`, {
      state: {
        topic,
      },
    });
  };

  return (
    <div
      className={
        "flex flex-col justify-center w-72 bg-white rounded-xl p-4 gap-4"
      }
    >
      <p className={"text-md font-bold line-clamp-2"} title={topic.topic}>
        {topic.topic}
      </p>

      <div>
        <div className={"flex justify-between"}>
          <div className={"flex flex-col items-center gap-1"}>
            <div className={"flex items-center gap-1"}>
              <p className={"text-2xl"}>{topic.correctQuestions}</p>
              <LuBadgeCheck size={34} className={"text-green-500"} />
            </div>
            <p className={"text-sm"}>Aciertos</p>
          </div>

          <div className={"flex flex-col items-center gap-1"}>
            <div className={"flex items-center gap-1"}>
              <p className={"text-2xl"}>{topic.failedQuestions}</p>
              <LuBadgeAlert size={34} className={"text-red-500"} />
            </div>
            <p className={"text-sm"}>Repasar</p>
          </div>

          <div className={"flex flex-col items-center gap-1"}>
            <div className={"flex items-center gap-1"}>
              <p className={"text-2xl"}>{undiscoverQuestions}</p>
              <LuBadgeHelp size={34} className={"text-gray-500"} />
            </div>
            <p className={"text-sm"}>Descubrir</p>
          </div>
        </div>
        {/*<progress className="progress" value={progress} max="100" />*/}
      </div>

      <button
        className={"btn btn-sm btn-block btn-primary"}
        onClick={onOpenQuiz}
      >
        <LuTestTube2 size={20} />
        Tema Quiz
      </button>
    </div>
  );
};
