import { usePocketActions } from "../../../hooks/use-pocket-actions";
import useSWR from "swr";
import { Loader } from "../../../components/loader";

export const Learn = () => {
  const { getUserFailedQuestions } = usePocketActions();

  const { data, error, isLoading } = useSWR(
    "/getUserFailedQuestions",
    getUserFailedQuestions,
    { fallbackData: [], revalidateOnFocus: false },
  );

  console.log(data);
  // TODO: add 4 options
  // TODO: salen todas las descubiertas y puedes filtrar por no correctas,
  // TODO: test de incorrectas
  return (
    <div>
      <p>Repasar</p>
      <p>Aqui podrás estudiar todas las preguntas que has descubierto</p>
      <p>
        Para descubrir mas preguntas, haz los Quiz diarios. Cada dia son
        diferentes
      </p>
      <button className={"btn btn-sm btn-secondary"}>Probar</button>
      {isLoading && <Loader />}
      {error && <p>Error...</p>}
      {data.map((q, i) => (
        <div key={`kFailedQuestion-${q.question.id}-${i}`}>
          <p>{q.question.question}</p>
          <p className={"text-red-400"}>{q.answer.answer}</p>
          <p className={"text-green-400"}>{q.correctAnswer.answer}</p>
        </div>
      ))}
      <div className="join">
        <button className="join-item btn">1</button>
        <button className="join-item btn btn-active">2</button>
        <button className="join-item btn">3</button>
        <button className="join-item btn">4</button>
      </div>
    </div>
  );
};
