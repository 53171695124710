import { TbLogout } from "react-icons/tb";
import { usePocket } from "../../PocketContext";
import { Link } from "react-router-dom";
import { MdAdminPanelSettings, MdSpaceDashboard } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { MenuLink } from "../../components/menu-link";

export const Header = () => {
  const { user, login, logout } = usePocket();

  return (
    <header className={"flex items-center justify-between p-4"}>
      <Link to={"/"}>
        <h1 className={"card-title"}>OposQuiz</h1>
      </Link>

      <div className={"flex gap-4"}>
        {user ? (
          <div className={"flex gap-4 items-center"}>
            <ul className="menu menu-vertical lg:menu-horizontal bg-base-200 rounded-box">
              <MenuLink name={"Inicio"} Icon={AiFillHome} to={"/"} />
              <MenuLink
                name={"Dashboard"}
                Icon={MdSpaceDashboard}
                to={"/dashboard"}
              />
              {/*<MenuLink name={'Perfil'} Icon={FaUser} to={'/dashboard'} />*/}
              {(user?.email === "alberto.quil3s@gmail.com" ||
                user.email === "yeissuaf@gmail.com") && (
                <MenuLink
                  name={"Admin"}
                  Icon={MdAdminPanelSettings}
                  to={"/admin/modules"}
                />
              )}
            </ul>

            <div className="avatar">
              <div className="w-12 rounded-full">
                <img
                  src={user.avatar}
                  referrerPolicy="no-referrer"
                  alt={"user avatar"}
                />
              </div>
            </div>
            <button className={"btn btn-square"} onClick={logout}>
              <TbLogout size={24} />
            </button>
          </div>
        ) : (
          <button onClick={login} className={"btn"}>
            Iniciar sesión
          </button>
        )}
      </div>
    </header>
  );
};
