import useSWR from "swr";
import { usePocket } from "../../../PocketContext";
import { usePocketActions } from "../../../hooks/use-pocket-actions";
import { Module } from "../../../components/module";
import { useMemo } from "react";

export const Profile = () => {
  const { user } = usePocket();
  const { getProfileStats, getUserModules } = usePocketActions();

  const { data: stats, isLoading } = useSWR(
    "/getProfileStats",
    getProfileStats,
    {
      fallbackData: {},
    },
  );

  const { data: modules } = useSWR("/getUserModules", getUserModules, {
    fallbackData: [],
  });

  const { level } = useMemo(() => {
    if (!stats.done) return { level: 1, progress: 0 };

    const correctPoints = 10;
    const failedPoints = -5;
    const pointsPerLevel = 100; // Cada 100 puntos un nivel

    const totalPoints =
      stats.correct * correctPoints + stats.failed * failedPoints;

    const _level = Math.max(1, Math.floor(totalPoints / pointsPerLevel));
    const progress = totalPoints % pointsPerLevel;

    return { level: _level, progress };
  }, [stats]);

  return (
    <div className={"flex flex-col gap-8"}>
      <div className={"flex items-center justify-between gap-4"}>
        <div className={"flex items-center gap-8"}>
          <div className="avatar">
            <div className="w-32 rounded-xl">
              <img
                src={user?.avatar}
                referrerPolicy="no-referrer"
                alt={"user avatar"}
              />
            </div>
          </div>
          <div>
            <p className={"text-3xl"}>{user.name}</p>
            <p className={"text-xl text-gray-500"}>{user.email}</p>
          </div>
        </div>
        <div>
          <div className="stats shadow">
            <div className="stat place-items-center">
              <div className="stat-title">Preguntas</div>
              <div className="stat-value">{`${stats?.done ?? 0}/${stats?.total ?? 0}`}</div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Nivel</div>
              <div className="stat-value text-primary">{level}</div>
            </div>

            <div className="stat place-items-center">
              <div className="stat-title">Logros</div>
              <div className="stat-value">0</div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <p>Loading...</p>}

      <div className={"flex flex-wrap gap-4 justify-center"}>
        {modules.map((module) => (
          <Module key={`kModule-${module.id}`} module={module} />
        ))}
      </div>
    </div>
  );
};
