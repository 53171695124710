import { useMemo } from "react";
import { Countdown } from "../../components/countdown";
import { getNextDailyQuizDate, getQuizNumber } from "../../utils/dates";

export const Home = () => {
  const nextQuizDate = useMemo(getNextDailyQuizDate, []);
  const quizNumber = useMemo(getQuizNumber, []);
  const isQuizAvailabe = useMemo(
    () => new Date() > nextQuizDate,
    [nextQuizDate],
  );

  // TODO: check si no ha contestado al primero
  return (
    <section
      className={
        "flex flex-col gap-10 items-center mt-8 mb-8 max-w-2xl mx-auto"
      }
    >
      <div className={"w-full"}>
        <h1 className={"text-2xl font-bold"}>Bienvenido a OposQuiz</h1>
        <p>Prepárate para tu oposición con nuestras preguntas diarias.</p>
      </div>

      <div className={"w-full"}>
        <h2 className={"text-l font-bold"}>¿Qué es OposQuiz?</h2>
        <p>
          OposQuiz es tu compañero de estudio para las oposiciones. Cada día, te
          presentamos un conjunto de preguntas cuidadosamente seleccionadas para
          ayudarte a repasar y mejorar tus habilidades. ¡Aprovecha esta
          oportunidad para fortalecer tus conocimientos y alcanzar tus
          objetivos!
        </p>
      </div>

      <div className={"w-full"}>
        <h2 className={"text-l font-bold"}>¿Cómo funciona?</h2>
        <ul>
          <li>
            Responde las preguntas diarias y descubre tus áreas de mejora.
          </li>
          <li>
            Mira las respuestas para conocer las soluciones y estudiarlas
            detenidamente.
          </li>
          <li>
            ¡No te preocupes si fallas! Aprender de los errores es parte del
            proceso.
          </li>
        </ul>
      </div>

      {isQuizAvailabe ? (
        <div>
          <p>{`El Quiz de hoy te está esperando`}</p>
          <a href={"/quiz"} className={"btn btn-primary"}>
            Comenzar
          </a>
        </div>
      ) : (
        <div className={"w-fit flex flex-col gap-2 items-center"}>
          <span>{`El Quiz ${quizNumber + 1} estará disponible en`}</span>
          <Countdown endDate={nextQuizDate} />
        </div>
      )}

      <div>
        <a className={"btn btn-primary"} href="/auth">
          Comienza tu desafío diario
        </a>
      </div>
    </section>
  );
};
