import { NavLink } from "react-router-dom";

export const MenuItem = ({ name, Icon, to, end = false }) => {
  return (
    <NavLink to={to} end={end}>
      {({ isActive, isPending, isTransitioning }) => (
        <button
          className={`btn btn-wide ${isActive ? "btn-primary" : "btn-outline"}`}
        >
          {Icon && <Icon size={24} />}
          {name}
        </button>
      )}
    </NavLink>
  );
};
