import { useEffect, useState } from "react";
import { calculateTimeLeft } from "../../utils/dates";
// import * as confetti from 'confettis'
// TODO: confetti para cuando completas el test con > 80% de acertadas (?)

export const Countdown = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  // useEffect(() => {
  //   if(timeLeft.seconds === 0) {
  //     confetti.create()
  //   }
  // }, [timeLeft.seconds])

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeLeft?.hours }}></span>
        </span>
        horas
      </div>
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeLeft?.minutes }}></span>
        </span>
        min
      </div>
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": timeLeft?.seconds }}></span>
        </span>
        sec
      </div>
    </div>
  );
};
