import { Home } from "./pages/home";
import { Auth } from "./pages/auth";

import { Admin } from "./pages/admin";
import { Quiz } from "./pages/quiz";
import { ProtectedRoute } from "./components/protected-route";
import { Root } from "./layout";
import { CreateQuestion } from "./pages/admin/questions/add";
import { Questions } from "./pages/admin/questions/list";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./pages/error";
import { Dashboard } from "./pages/dashboard";
import { Profile } from "./pages/dashboard/profile";
import { Learn } from "./pages/dashboard/learn";
import { Modules } from "./pages/admin/modules";
import { ModuleScreen } from "./pages/dashboard/module";

// TODO: 404 Not found custom
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/auth",
        element: <Auth />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
            children: [
              {
                index: true,
                element: <Profile />,
              },
              {
                path: "learn",
                element: <Learn />,
              },
              {
                path: "module/:moduleId",
                element: <ModuleScreen />,
              },
              {
                path: "module/:moduleId/quiz",
                element: <Quiz />,
              },
              {
                path: "module/:moduleId/topic/:topicId/quiz",
                element: <Quiz />,
              },
              {
                path: ":oppositionId/quiz",
                element: <Quiz />,
              },
            ],
          },
          {
            path: "/admin",
            element: <Admin />,
            children: [
              {
                index: true,
                element: <Questions />, // TODO: Será oppositions
              },
              {
                path: "questions/add",
                element: <CreateQuestion />,
              },
              {
                path: "modules",
                element: <Modules />,
              },
              {
                path: "modules/topics/:topicId",
                element: <Questions />,
              },
              {
                path: "modules/topics/:topicId/add",
                element: <CreateQuestion />,
              },
            ],
          },
        ],
      },
      { path: "*", element: <p>Not found</p> },
    ],
  },
]);
