import { QUIZ_START_DATE } from "../consts";

export const calculateTimeLeft = (endDate) => {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft = null;

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor(difference / (1000 * 60 * 60)),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const getDaysBetweenDates = (start, end) => {
  const diff = end - start;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export const getNextDailyQuizDate = () => {
  const nextDate = new Date();
  // nextDate.setHours(18);
  // nextDate.setMinutes(0);
  // nextDate.setSeconds(0);
  nextDate.setDate(nextDate.getDate() + 1);
  nextDate.setHours(0);
  nextDate.setMinutes(0);
  nextDate.setSeconds(0);
  nextDate.setMilliseconds(0);
  return nextDate;
};

export const getQuizNumber = () =>
  getDaysBetweenDates(QUIZ_START_DATE, new Date()) + 1;
