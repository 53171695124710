import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import PocketBase from "pocketbase";
import { useInterval } from "usehooks-ts";
import { jwtDecode } from "jwt-decode";

const fiveMinutesInMs = 1000 * 60 * 5;
const twoMinutesInMs = 1000 * 60 * 2;

const PocketContext = createContext({});

export const PocketProvider = ({ children }) => {
  const pb = useMemo(() => new PocketBase(process.env.REACT_APP_API_URL), []);

  const [token, setToken] = useState(pb.authStore.token);
  const [user, setUser] = useState(pb.authStore.model);

  useEffect(() => {
    return pb.authStore.onChange((token, model) => {
      setToken(token);
      setUser(model);
    });
  }, [pb]);

  const register = useCallback(
    async (email, password) => {
      return await pb
        .collection("users")
        .create({ email, password, passwordConfirm: password });
    },
    [pb],
  );

  const login = useCallback(async () => {
    // const authMethods = await pb.collection("users").listAuthMethods();
    const w = window.open();

    const authData = await pb.collection("users").authWithOAuth2({
      provider: "google",
      createData: { opposition: "n1sx70s1yv1nsnj" }, // TODO: selector de oposicion antes de crer cuenta
      urlCallback: (url) => {
        w.location.href = url;
      },
    });

    // const authData = await pb
    //   .collection("users")
    //   .authWithPassword("test", "080595kE");

    if (!authData?.token) {
      throw new Error("Invalid email or password");
    }

    const data = {
      avatar: authData.meta.avatarUrl,
      name: authData.meta.name,
    };
    await pb.collection("users").update(pb.authStore.model.id, data);

    return authData;
  }, [pb]);

  const logout = useCallback(() => {
    pb.authStore.clear();
  }, [pb]);

  const refreshSession = useCallback(async () => {
    if (!pb.authStore.isValid) return;
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded.exp;
    const expirationWithBuffer = (decoded.exp + fiveMinutesInMs) / 1000;
    if (tokenExpiration < expirationWithBuffer) {
      await pb.collection("users").authRefresh();
    }
  }, [pb, token]);

  useInterval(refreshSession, token ? twoMinutesInMs : null);

  return (
    <PocketContext.Provider
      value={{ register, login, logout, user, token, pb }}
    >
      {children}
    </PocketContext.Provider>
  );
};

export const usePocket = () => useContext(PocketContext);
