export const pseudoRandom = (seed) => {
  return () => {
    let x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
};

export const shuffle = (array, random = Math.random) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
