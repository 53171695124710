import useSWR from "swr";
import { usePocketActions } from "../../../hooks/use-pocket-actions";
import { useParams } from "react-router-dom";
import { Topic } from "../../../components/topic";

export const ModuleScreen = () => {
  const params = useParams();
  const { getModuleTopics } = usePocketActions();

  const { data: topics } = useSWR(
    `/getModuleTopics/${params.moduleId}`,
    getModuleTopics(params.moduleId),
    {
      fallbackData: [],
    },
  );

  return (
    <div className={"flex flex-col gap-8"}>
      <div className={"flex items-center justify-between gap-4"}></div>

      <div className={"flex flex-wrap gap-4 justify-center"}>
        {topics.map((topic) => (
          <Topic key={`kTopic-${topic.id}`} topic={topic} />
        ))}
      </div>
    </div>
  );
};
