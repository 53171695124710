import { shuffle } from "../../utils/data";
import { useCallback, useMemo, useState } from "react";
import { BiSolidError } from "react-icons/bi";
import { FaCheck } from "react-icons/fa6";

export const Question = ({
  question,
  index,
  onSelectAnswer = ({ questionId, answerId }) => {},
  resolve,
}) => {
  const [userAnswer, setUserAnswer] = useState(null);

  const answers = useMemo(
    () => shuffle([...question.answers]),
    [question.answers],
  );
  const correctAnswerId = useMemo(
    () => question.answers.find((answer) => answer.isCorrect).id,
    [question],
  );

  const onChange =
    ({ questionId, answerId }) =>
    () => {
      setUserAnswer(answerId);
      onSelectAnswer({ questionId, answerId });
    };

  const getResolveIcon = useCallback(
    (answerId) => {
      if (!resolve) return;
      if (userAnswer !== answerId && correctAnswerId !== answerId)
        return <FaCheck size={24} opacity={0} />;

      return userAnswer === correctAnswerId || correctAnswerId === answerId ? (
        <FaCheck size={24} color={"oklch(var(--su))"} />
      ) : (
        <BiSolidError size={24} color={"oklch(var(--er))"} />
      );
    },
    [resolve, userAnswer, correctAnswerId],
  );

  // TODO: add a) b) c) .... (?)
  // TODO: add hover effect
  return (
    <li>
      <p>{`${index + 1}. ${question.question}`}</p>
      {answers.map((answer) => (
        <div
          key={`kAnswer-${answer.id}`}
          className={`flex gap-4 p-2 mt-2 items-center`}
        >
          {getResolveIcon(answer.id)}
          <input
            disabled={resolve}
            type="radio"
            id={`radio-${answer.id}-${index}`}
            name={`radio-${index}`}
            className="radio"
            onChange={onChange({
              questionId: question.id,
              answerId: answer.id,
            })}
          />
          <label htmlFor={`radio-${answer.id}-${index}`}>{answer.answer}</label>
        </div>
      ))}
    </li>
  );
};
