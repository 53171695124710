import { Outlet } from "react-router-dom";
import { MenuItem } from "../../components/menu-item";

import { GiProgression } from "react-icons/gi";
import { FaBookOpen } from "react-icons/fa6";
import { LuTestTube2 } from "react-icons/lu";
import { usePocket } from "../../PocketContext";

export const Dashboard = () => {
  const { user } = usePocket();

  return (
    <div className={"flex w-full h-full gap-8 p-4"}>
      <aside className={"w-60"}>
        <div className={"flex flex-col gap-4"}>
          <MenuItem
            name={"Mi progreso"}
            Icon={GiProgression}
            to={""}
            end={true}
          />
          <MenuItem
            name={"Quiz"}
            Icon={LuTestTube2}
            to={`${user.opposition}/quiz`}
          />
          <MenuItem name={"Repasar"} Icon={FaBookOpen} to={"learn"} />
        </div>
      </aside>

      <div className={"w-full bg-[#dee1e6] p-8 rounded-3xl"}>
        <Outlet />
      </div>
    </div>
  );
};
