import { useMemo, useState } from "react";
import { Question } from "../../components/question";
import * as confetti from "confettis";
import useSWR from "swr";
import { usePocketActions } from "../../hooks/use-pocket-actions";
import { useLocation, useParams } from "react-router-dom";

export const Quiz = () => {
  const params = useParams();
  const { state } = useLocation();
  const { addUserAnswers, getQuiz } = usePocketActions();

  const {
    data: quiz,
    isLoading,
    error,
  } = useSWR(
    `/getQuiz/${params.oppositionId ? params.oppositionId : ""}_${params.moduleId}/${params.topicId ? params.topicId : ""}`,
    getQuiz(params),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    },
  );

  const [userResponse, setUserResponse] = useState({});
  const [resolved, setResolved] = useState(false);
  // const quizNumber = useMemo(getQuizNumber, []);

  const topic = useMemo(() => state?.topic ?? {}, [state]);

  const onSelectAnswer = ({ questionId, answerId }) => {
    setUserResponse((old) => ({
      ...old,
      [questionId]: answerId,
    }));
  };

  const onResolve = async () => {
    await addUserAnswers(userResponse);

    setResolved(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    // qId: ansId
    const all = quiz.every(
      (q) => q.answers.find((a) => a.id === userResponse[q.id])?.isCorrect,
    );
    if (all) confetti.create({ count: 350 });
  };

  const getStepAnswer = (question) => {
    if (!resolved) return userResponse[question.id] ? "step-neutral" : "";

    return question.answers.find((a) => a.id === userResponse[question.id])
      ?.isCorrect
      ? "step-primary"
      : "step-error";
  };

  // TODO: add test aprobado/supendido
  // TODO: numero de fallos
  // TODO: repasar preguntas falladas

  // TODO: mover quiz logic to compoonent, para poder pasarle preguntas directamente, por ejemplo daily quiz, failed questions...

  // TODO: opcion para reportar pregunta - modal mas confirmation
  return (
    <section>
      {/*<div className={"mb-8"}>*/}
      <h1 className={"text-2xl font-bold"}>
        {topic?.topic ? topic.topic : "Quiz global"}
      </h1>
      {/*<h2 className={"text-2xl "}>Preguntas del Día</h2>*/}
      {/*<p>Aquí están las preguntas de hoy. ¡Pon a prueba tus conocimientos!</p>*/}
      {/*</div>*/}

      {/*<h2 className={"text-2xl font-bold"}>{`Quiz ${quizNumber}`}</h2>*/}

      {isLoading && <p>Loading...</p>}
      {error && <p>{`Error: ${error}`}</p>}

      <ul className={"steps w-full mt-4 mb-8"}>
        {quiz.map((question, i) => (
          <li
            key={`kStep-${i}`}
            data-content={i + 1}
            className={`step ${getStepAnswer(question)}`}
          />
        ))}
      </ul>

      <ol className={"flex flex-col gap-8"}>
        {quiz.map((question, i) => (
          <Question
            key={`kQuestion-${question.id}`}
            question={question}
            index={i}
            onSelectAnswer={onSelectAnswer}
            resolve={resolved}
          />
        ))}
      </ol>

      <div className={"flex justify-around m-8"}>
        {resolved ? (
          <div className={"flex w-full justify-around"}>
            <button className={"btn"}>Repetir</button>
            <button className={"btn btn-secondary"}>Hacer mas</button>
            <button className={"btn btn-secondary"}>Ver progreso</button>
          </div>
        ) : (
          <button
            className={`btn btn-secondary ${Object.keys(userResponse).length === quiz.length ? "" : "btn-disabled"}`}
            onClick={onResolve}
          >
            Resolver/Corregir
          </button>
        )}
      </div>

      {/*<footer>*/}
      {/*  <a href="#">Volver a la página principal</a>*/}
      {/*</footer>*/}
    </section>
  );
};
