import { useNavigate } from "react-router-dom";
import { usePocketActions } from "../../../hooks/use-pocket-actions";
import useSWR from "swr";
import { Loader } from "../../../components/loader";

export const Modules = () => {
  const navigate = useNavigate();
  const { getModules } = usePocketActions();

  const { data: modules, isLoading } = useSWR("/getModules", getModules, {
    fallbackData: [],
  });

  const openQuestions = (topicId) => () => {
    navigate(`topics/${topicId}`);
  };

  // TODO: add id opposition on url
  return (
    <section>
      <div className={"flex justify-between items-center"}>
        <h1 className={"text-2xl"}>{`Bloques (${modules.length})`}</h1>
        <button
          className={"btn btn-secondary"}
          onClick={() => navigate("modules/add")}
        >
          Crear nuevo bloque
        </button>
      </div>

      {isLoading && <Loader />}

      <div className={"flex flex-col gap-8"}>
        {modules.map(({ id, name, topics }, i) => (
          <div key={`kModule-${id}`}>
            <p className={"text-md font-bold"}>{name}</p>
            <div className={"flex flex-col gap-2"}>
              {topics.map((topic) => (
                <div className={"flex items-center justify-between gap-4"}>
                  <p key={`kTopic-${topic.id}`} className={"text-sm"}>
                    {topic.name}
                  </p>
                  <button
                    className={"btn btn-sm btn-outline"}
                    onClick={openQuestions(topic.id)}
                  >
                    Ver preguntas
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
