import { useCallback, useState } from "react";
import useSWRMutation from "swr/mutation";
import { useParams } from "react-router-dom";
import { usePocketActions } from "../../../../hooks/use-pocket-actions";
import { LuBadgeCheck, LuBadgeHelp, LuBadgeMinus } from "react-icons/lu";

const Answer = ({
  i,
  isCorrect = false,
  isLast = false,
  onPressCorrect = (i) => () => {},
  onPressDelete = (i) => () => {},
}) => {
  return (
    <div>
      <div className={"flex items-center justify-between gap-2 pt-4 pb-2"}>
        <label htmlFor={`answer${i + 1}`}>{`Respuesta ${i + 1}`}</label>
        {isLast && (
          <button
            type="button"
            className={"btn btn-sm btn-link text-red-400"}
            onClick={onPressDelete(i)}
          >
            <LuBadgeMinus size={24} />
            Borrar
          </button>
        )}
      </div>
      <label className="input input-bordered flex items-center gap-2">
        <input
          key={i}
          type="text"
          id={`answer${i + 1}`}
          name={`answer${i + 1}`}
          required="required"
          placeholder={`Respuesta ${i + 1}`}
          className="grow"
        />
        <button
          type="button"
          className={`btn btn-sm ${isCorrect ? "bg-green-300" : ""}`}
          onClick={onPressCorrect(i)}
        >
          {isCorrect ? <LuBadgeCheck size={24} /> : <LuBadgeHelp size={24} />}
          <span>{isCorrect ? "Correcta" : "Marcar como correcta"}</span>
        </button>
      </label>
    </div>
  );
};

export const CreateQuestion = () => {
  const params = useParams();
  const { addQuestion } = usePocketActions();
  const [answerCount, setAnswerCount] = useState(1);
  const [correctAnswer, setCorrectAnswer] = useState(null);

  const { trigger: onAddQuestion } = useSWRMutation(
    "/addQuestion",
    async (_, { arg }) => await addQuestion(arg),
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (correctAnswer === null) return;

      const data = Object.fromEntries(new FormData(e.target));
      const answers = Object.keys(data)
        .map((key, index) => {
          if (key.startsWith("answer") && data[key]) {
            return {
              answer: data[key],
              isCorrect: index === correctAnswer + 1,
            };
          }
          return null;
        })
        .filter(Boolean);

      try {
        await onAddQuestion({
          topicId: params?.topicId,
          question: data.question,
          answers,
        });
        e.target.reset();
        setAnswerCount(1);
        setCorrectAnswer(null);
      } catch (e) {
        console.error(e);
      }
    },
    [params, onAddQuestion, correctAnswer],
  );

  const onPressCorrect = (i) => () => {
    setCorrectAnswer(i);
  };

  const onPressDeleteAnswer = (i) => () => {
    if (correctAnswer && correctAnswer === i) {
      setCorrectAnswer(null);
    }

    setAnswerCount((c) => c - 1);
  };

  return (
    <div>
      <form className={"flex flex-col gap-4"} onSubmit={onSubmit}>
        <label htmlFor="question">Pregunta</label>
        <input
          type="text"
          id="question"
          name="question"
          required="required"
          placeholder="Pregunta"
          className="input input-bordered w-full"
        />

        {[...Array(answerCount)].map((_, i) => (
          <Answer
            i={i}
            isLast={i > 1 && i === Array(answerCount).length - 1}
            isCorrect={correctAnswer === i}
            onPressCorrect={onPressCorrect}
            onPressDelete={onPressDeleteAnswer}
          />
        ))}
        <button
          type="button"
          onClick={() => setAnswerCount(answerCount + 1)}
          className={"btn"}
        >
          Añadir respuesta
        </button>

        <button className={"btn btn-primary"}>Crear</button>
      </form>
    </div>
  );
};
