import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { usePocket } from "../../PocketContext";

export const Admin = () => {
  const { user } = usePocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !user ||
      (user.email !== "alberto.quil3s@gmail.com" &&
        user.email !== "yeissuaf@gmail.com")
    ) {
      return navigate("/");
    }
  }, [navigate, user]);

  return (
    <div className={"mx-auto max-w-3xl py-8"}>
      <Outlet />
    </div>
  );
};
