import { NavLink } from "react-router-dom";

export const MenuLink = ({ name, Icon, to, end = false }) => {
  return (
    <li>
      <NavLink
        to={to}
        end={end}
        className={({ isActive, isPending }) => (isActive ? "active" : "")}
      >
        <Icon size={20} />
        {name}
      </NavLink>
    </li>
  );
};
