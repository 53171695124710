import { usePocket } from "../../PocketContext";
import { getDaysBetweenDates } from "../../utils/dates";
import { pseudoRandom, shuffle } from "../../utils/data";

export const usePocketActions = () => {
  const { pb, user } = usePocket();

  const getModules = async () => {
    const response = await pb.collection("modules").getFullList({
      expand: "topics_via_module",
      sort: "order",
    });

    return response.map((item) => ({
      id: item.id,
      // opposition: item.opposition,
      name: item.name,
      topics: item?.expand?.topics_via_module
        ? item.expand.topics_via_module.map((topic) => ({
            id: topic.id,
            name: topic.name,
          }))
        : [],
    }));
  };

  const getQuestions = (topicId) => async () => {
    if (!topicId) return [];

    const response = await pb.collection("questions").getFullList({
      expand: "answers_via_question",
      filter: `topic = "${topicId}"`,
    });

    return response.map((item) => ({
      id: item.id,
      question: item.question,
      answers: item?.expand?.answers_via_question
        ? item.expand.answers_via_question.map((answer) => ({
            id: answer.id,
            answer: answer.answer,
            isCorrect: answer.isCorrect,
          }))
        : [],
    }));
  };

  const getDaily = async () => {
    // TODO: fetch pb user-questios filtering x showed
    const allQuestions = await getQuestions();

    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const day = getDaysBetweenDates(start, now);

    const seed = day + now.getFullYear(); // TODO: cambiar seed si se lanzan a las 6 por ejemplo, estarian mitad dia con las mismas de ayer

    const random = pseudoRandom(seed);
    const questions = shuffle([...allQuestions], random);

    return questions.slice(0, 5);
  };

  const addUserAnswers = async (userAnswers) => {
    for (const questionId of Object.keys(userAnswers)) {
      await pb.collection("user_question_answer").create({
        user: pb.authStore.model.id,
        question: questionId,
        answer: userAnswers[questionId],
      });
    }
  };

  const deleteQuestion = async (questionId) => {
    await pb.collection("questions").delete(questionId);
  };

  const getUserAnswers = async () => {
    const response = await pb.collection("user_question_answer").getFullList({
      expand: "answer",
      filter: `user.id="${pb.authStore.model.id}"`,
    });

    const { good, bad } = response.reduce(
      (acc, curr) => {
        if (curr.expand.answer.isCorrect) {
          acc.good++;
        } else {
          acc.bad++;
        }

        return {
          ...acc,
        };
      },
      { good: 0, bad: 0 },
    );

    return {
      good,
      bad,
      total: response.length,
    };
  };

  const getUserFailedQuestions = async () => {
    const response = await pb.collection("user_question_answer").getFullList({
      expand: "answer,question",
      filter: `user.id="${pb.authStore.model.id}" && answer.isCorrect = false`,
    });

    const data = {};
    for (const record of response) {
      if (!data[record.question]) {
        const correctAnswer = await pb
          .collection("answers")
          .getFirstListItem(
            `question = "${record.question}" && isCorrect = true`,
          );

        data[record.question] = {
          date: new Date(record.created),
          question: record.expand.question,
          answer: record.expand.answer,
          correctAnswer,
        };
      }
    }

    return Object.values(data);
  };

  const addQuestion = async ({ topicId, question, answers }) => {
    // TODO: proteget db solo yo puedo insertar*
    // TODO: ahora mismo esta libre para todos
    if (!topicId) return;

    const record = await pb.collection("questions").create({
      question,
      topic: topicId,
    });

    for (const answer of answers) {
      await pb.collection("answers").create({
        answer: answer.answer,
        isCorrect: answer.isCorrect,
        question: record.id,
      });
    }
  };

  const getOppositionModules = (oppositionId) => async () => {
    if (!oppositionId) return;

    return await pb.collection("modules").getFullList({
      filter: `opposition = "${oppositionId}"`,
      sort: "order",
      expand: "oppositions_via_module",
    });
  };

  const getUserModules = async () => {
    return await pb.collection("user_modules").getFullList({
      filter: `userId = "${user.id}"`,
    });
  };

  const getProfileStats = async () => {
    try {
      return await pb
        .collection("user_stats")
        .getFirstListItem(`user = "${user.id}"`);
    } catch (e) {
      return {
        total: 0, // TODO: Arreglar SQL para que devuelva aunque los usuarios no hayan hecho ningun reto
        correct: 0,
        failed: 0,
        done: 0,
      };
    }
  };

  const getModuleTopics = (moduleId) => async () => {
    if (!moduleId) return;

    return await pb.collection("user_topics").getFullList({
      filter: `moduleId = "${moduleId}"`,
    });
  };

  const getModuleQuiz = async (moduleId) => {
    if (!moduleId) return;
    // TODO: add paginado, para el hacer más de 10en10

    const res = await pb.collection("modules").getOne(moduleId, {
      expand: "topics_via_module.questions_via_topic.answers_via_question",
      filter: `id = "${moduleId}"`,
    });

    const moduleQuestions = res.expand.topics_via_module
      .flatMap((topic) => {
        return topic.expand?.questions_via_topic.map((question) => {
          const answers = question.expand.answers_via_question.map(
            (answer) => ({
              id: answer.id,
              answer: answer.answer,
              isCorrect: answer.isCorrect,
            }),
          );

          return {
            id: question.id,
            question: question.question,
            answers,
          };
        });
      })
      .filter(Boolean);

    // const now = new Date();
    // const start = new Date(now.getFullYear(), 0, 0);
    // const day = getDaysBetweenDates(start, now);

    // TODO: Random o seed por dia (?)
    const seed = Date.now();

    const random = pseudoRandom(seed);
    const questions = shuffle([...moduleQuestions], random);

    return questions.slice(0, 5);
  };

  const getTopicQuiz = async (topicId) => {
    if (!topicId) return;
    // TODO: add paginado, para el hacer más de 10en10

    const res = await pb.collection("topics").getOne(topicId, {
      expand: "questions_via_topic.answers_via_question",
      filter: `id = "${topicId}"`,
    });

    const topicQuestions = res.expand?.questions_via_topic
      ? res.expand?.questions_via_topic
          .map((question) => {
            const answers = question.expand.answers_via_question.map(
              (answer) => ({
                id: answer.id,
                answer: answer.answer,
                isCorrect: answer.isCorrect,
              }),
            );

            return {
              id: question.id,
              question: question.question,
              answers,
            };
          })
          .filter(Boolean)
      : [];

    // const now = new Date();
    // const start = new Date(now.getFullYear(), 0, 0);
    // const day = getDaysBetweenDates(start, now);

    // TODO: Random o seed por dia (?)
    const seed = Date.now();

    const random = pseudoRandom(seed);
    const questions = shuffle([...topicQuestions], random);

    return questions.slice(0, 5);
  };

  const getOppositionQuiz = async (oppositionId) => {
    if (!oppositionId) return;
    // TODO: add paginado, para el hacer más de 10en10

    const response = await pb.collection("questions").getFullList({
      expand: "answers_via_question",
      // TODO: add filter by oppositionId
    });

    const oppositionQuestions = response.map((item) => ({
      id: item.id,
      question: item.question,
      answers: item?.expand?.answers_via_question
        ? item.expand.answers_via_question.map((answer) => ({
            id: answer.id,
            answer: answer.answer,
            isCorrect: answer.isCorrect,
          }))
        : [],
    }));

    // const now = new Date();
    // const start = new Date(now.getFullYear(), 0, 0);
    // const day = getDaysBetweenDates(start, now);

    // TODO: Random o seed por dia (?)
    const seed = Date.now();

    const random = pseudoRandom(seed);
    const questions = shuffle([...oppositionQuestions], random);

    return questions.slice(0, 5);
  };

  const getQuiz = (params) => async () => {
    if (params?.topicId) {
      return getTopicQuiz(params.topicId);
    } else if (params?.moduleId) {
      return getModuleQuiz(params.moduleId);
    }

    return getOppositionQuiz(params.oppositionId);
  };

  return {
    getModules,
    getQuestions,
    getDaily,
    addUserAnswers,
    deleteQuestion,
    getUserAnswers,
    getUserFailedQuestions,
    addQuestion,

    getOppositionModules,
    getUserModules,
    /**
     * @deprecated
     * use getQuiz
     */
    getModuleQuiz,

    getProfileStats,

    getModuleTopics,

    getQuiz,
  };
};
